import React, { FC } from "react";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";
import { MainLayout } from "@/layouts/MainLayout";
import { HighlightBoxes } from "@/components/HighlightBoxes";
import { QualitiesList } from "@/components/QualitiesList";
import { ProductCardWrapper } from "@/components/ProductCardWrapper";
import { Link } from "@/components/basic/Link";
import { SiteFooterNewsletterButton } from "@/components/basic/SiteFooterNewsletterButton";
import { SiteFooterWrapper } from "@/components/basic/SiteFooterWrapper";
import { PageProps } from "gatsby";
import { SEO } from "@/components/basic/SEO";

const ProductPageTemplate: FC<PageProps<
  any,
  GatsbyTypes.Query_contentfulProductLandingPageArgs
>> = ({ pageContext }) => {
  return (
    <MainLayout>
      <Helmet>
        <body className="ltr travel-industry-solutions-subpage" id="intro" />
      </Helmet>
      <SEO
        title={pageContext.metaTitle}
        description={pageContext.metaDescription?.metaDescription}
        keywords={pageContext.metaKeywords}
      />
      <ProductCardWrapper
        shortDescription={
          pageContext.shortDescription &&
          pageContext.shortDescription.shortDescription
        }
        descriptionTitle={pageContext.descriptionTitle}
        mobileHeroImageUrl={
          pageContext.mobileHeroImage && pageContext.mobileHeroImage.file.url
        }
        heroImageUrl={pageContext?.heroImage?.file?.url}
        sendGridTemplateId={pageContext.sendGridTemplateId}
        showProductCardBtn={pageContext.productCard}
      />
      <div className="l-wrapper clearfix">
        <div className="l-8-cols-desktop l-dbl-push-bottom l-island l-bleed clearfix l-push-top">
          <div
            className="l-full l-push-bottom text-section"
            style={{ marginTop: "4rem" }}
          >
            <h3
              className="color-primary align-center l-push-bottom"
              style={{ fontSize: "1.8em" }}
            >
              {pageContext?.productDescriptionTitle &&
                pageContext?.productDescriptionTitle}
            </h3>
            <ReactMarkdown allowDangerousHtml={true}>
              {pageContext.description?.description}
            </ReactMarkdown>
          </div>
        </div>
        <div className="clearfix">
          <div
            className="l-8-cols-desktop l-island"
            style={{ marginBottom: "5rem" }}
          >
            <div className="image-object">
              <img
                alt={pageContext.diagram?.title}
                src={pageContext.diagram?.file?.url}
              />
            </div>
          </div>
        </div>
      </div>
      <HighlightBoxes
        title={pageContext.benefitsTitle}
        markdown={pageContext.benefits?.benefits}
      />
      <QualitiesList
        title={pageContext?.highlightsTitle}
        markdown={pageContext.highlights?.highlights}
      />
      <div className="l-push-bottom l-push-top align-center">
        Want to know more?
      </div>
      <div className="align-center l-dbl-push-bottom">
        <a
          className="button button button-ghost-primary"
          href="/contact#contact-form"
        >
          SEND US A MESSAGE
        </a>
      </div>
      <SiteFooterWrapper>
        <SiteFooterNewsletterButton />
        <Link
          classname="button button-primary button-request-demo"
          url="/demo-form"
          text="request a demo"
        />
      </SiteFooterWrapper>
    </MainLayout>
  );
};

export default ProductPageTemplate;
